import ItemShippingSelect from 'features/checkout/components/ItemShippingSelect';
import FormCurrencyInputFancy from '@c/forms/controls/FormCurrencyInputFancy';
import FormLabel from '@c/forms/controls/FormLabel';
import FormSelect from '@c/forms/controls/FormSelect';
import { FedExIcon, USPSIcon } from '@c/icons';
import { UPS } from '@c/icons/carriers';
import AddAddressModal from '@c/modals/AddAddressModal';
import AddPaymentMethodModal from '@c/modals/AddPaymentMethodModal';
import { BProcess, ProcessStep } from '@c/process/BaseProcess';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import SafeImage from '@ui/SafeImage';
import { calcTax, formatCurrency } from '@util/index';
import { capitalize } from 'lodash';
import { CreditCardIcon, PencilIcon } from 'lucide-react';
import { ProductDocument, Variation } from 'models/product';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useMakeAnOffer } from '../hooks/useMakeAnOffer';
import { OfferSchemaType, StepType } from '../types';
import {
  validatePaymentMethod,
  validatePrice,
  validateShippingAddress,
  validateShippingOptions,
  validateTermsAndConditions,
} from '../utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { calculateShipping, getShippingRegion } from '@util/firestore/cart';
import { getPaymentMethods } from '@util/firestore/payments';
import { useAuth } from 'context/AuthContext';

interface MakeAnOfferFormProps {
  product: ProductDocument;
  selectedVariations: Variation[] | null;
  dismiss: () => void;
}

export const MakeAnOfferForm = ({
  product,
  selectedVariations,
  dismiss,
}: MakeAnOfferFormProps) => {
  const { userDoc } = useAuth();
  const [openModal, setOpenModal] = useState<'address' | 'payment' | ''>('');
  const [currentStep, setCurrentStep] = useState(0);

  const [ratesError, setRatesError] = useState<Error | null>(null);

  const taxMutation = useMutation({
    mutationFn: (form: OfferSchemaType) =>
      calcTax({
        form,
        products: [product],
      }),
    onSuccess: (data) => {
      useFormReturn.setValue('tax', data);
    },
  });
  const addressOptions =
    userDoc?.addresses?.map((address) => ({
      id: `${address.address_line1}`,
      // using this validation to avoid showing empty values
      label: [
        address.address_line1,
        address.city_locality,
        address.state_province,
        address.postal_code,
      ]
        .filter(Boolean)
        .join(', '),
      value: JSON.stringify(address),
    })) ?? [];
  const { useFormReturn, getPricePercentageOff, onSubmit } = useMakeAnOffer({
    product,
    dismiss,
    selectedVariations,
  });
  const { data: paymentMethodData } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: getPaymentMethods,
    enabled: !!userDoc,
  });
  const paymentOptions = paymentMethodData?.map((paymentMethod: any) => ({
    id: paymentMethod.id ?? '',
    label: `${capitalize(paymentMethod.brand)} - ${paymentMethod.last4}`,
    value: paymentMethod.id ?? '',
    selectedDisplay: (
      <div className="flex items-center gap-2">
        {['visa', 'mastercard', 'amex'].includes(paymentMethod.brand ?? '') ? (
          <SafeImage
            src={`/cc_brands/${paymentMethod.brand?.toLowerCase()}.png`}
            alt={paymentMethod.brand ?? ''}
            width={32}
            height={20}
            className="mr-2 h-12 w-16 object-contain"
          />
        ) : (
          <CreditCardIcon className="h-12 w-12" />
        )}

        <span>{`Card ending in ${paymentMethod.last4}`}</span>
      </div>
    ),
  }));

  const form = useFormReturn.watch();

  function handleOfferClick(price: number) {
    useFormReturn.setValue('price', Number(price.toFixed(2)));
  }

  const letterSize = selectedVariations?.find((v) => !v.is_number && !v.color);
  const numberSize = selectedVariations?.find((v) => v.is_number && !v.color);
  const colorVariation = selectedVariations?.find((v) => v.color);
  const flatRateList: StepType[] = [
    'offer-price',
    'shipping-payment',
    'review-offer',
  ];
  const dynamicList: StepType[] = [
    'offer-price',
    'shipping-payment',
    'shipping-options',
    'review-offer',
  ];
  const steps: ProcessStep<StepType>[] = [
    {
      id: 'offer-price' as StepType,
      content: (
        <div className="flex h-full flex-col justify-between gap-[2.4rem]">
          <div className="flex flex-col gap-[2.4rem]">
            <span className="text-[2.1rem] font-semibold text-black">
              Offer Price
            </span>

            <div className="flex items-center justify-between">
              <div className="flex max-w-[30rem] items-center gap-[1.6rem]">
                <SafeImage
                  className="h-[6rem] w-[6rem] shrink-0 rounded-2xl"
                  alt={`${product.title} product image`}
                  src={product.thumbnail}
                />

                <div className="flex flex-col gap-[0.2rem]">
                  <div className="flex flex-col gap-y-2">
                    <span className="w-[22rem] truncate text-[1.7rem] font-semibold text-black">
                      {product.title}
                    </span>

                    <span className="flex flex-col text-[1.6rem] font-medium text-[#7F7F7F]">
                      {product.categories.join(' > ')}
                      {!!selectedVariations?.length && (
                        <>
                          <span>
                            Size: {letterSize?.size ?? ''}
                            {letterSize?.size && numberSize?.size ? '/' : ''}
                            {numberSize?.size ?? ''}
                          </span>
                          {colorVariation?.color && (
                            <span>Color: {colorVariation?.color}</span>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <span className="text-[2.1rem] font-bold text-black">
                {formatCurrency(product.price)}
              </span>
            </div>

            <div className="border-l border-zinc-200 pl-4">
              <span className="text-[#444444]">
                All offers are binding and expire in 48 hours. If the seller
                accepts your offer, payment will be processed.
              </span>
            </div>

            <div className="flex flex-col items-start gap-[1.6rem]">
              <Controller
                control={useFormReturn.control}
                name={`price`}
                render={({ field: { value, onChange, onBlur } }) => (
                  <div className="w-full rounded-[1.3rem] border-[1px] border-[#D9D9D9] pb-[1rem] pl-[2rem] pr-[1rem] pt-[1rem] !text-black">
                    <FormLabel
                      labelClasses={`text-[1.8rem] mt-4 ${
                        useFormReturn.formState.errors.price
                          ? 'text-brand-secondary'
                          : '!text-black'
                      }`}
                      value={'Your offer'}
                      className="flex items-center justify-between"
                    >
                      <FormCurrencyInputFancy
                        placeholder={'0.00'}
                        error={!!useFormReturn.formState.errors.price}
                        onChange={(e) => {
                          useFormReturn.clearErrors('price');
                          onChange(e);
                        }}
                        value={value > 0 ? value.toFixed(2) : ''}
                        defaultValue={form.price.toString()}
                        onBlur={onBlur}
                        currencyInputClassName={`!text-[1.6rem] ${
                          useFormReturn.formState.errors.price
                            ? 'text-brand-secondary'
                            : '!text-black'
                        }`}
                      />
                    </FormLabel>
                  </div>
                )}
              />

              <div className="flex w-full items-center justify-between gap-x-4">
                {[20, 15, 10].map((percentage) => {
                  const price = getPricePercentageOff({ product, percentage });
                  return (
                    <button
                      className={`flex w-full cursor-pointer flex-col items-center justify-center rounded-[1.3rem] p-4 ${
                        form.price === price
                          ? 'border border-brand-secondary bg-brand-secondary text-white'
                          : 'border border-brand-primary-lighter bg-transparent text-black hover:bg-brand-off-white'
                      }`}
                      key={percentage}
                      type="button"
                      onClick={() => handleOfferClick(price)}
                    >
                      <h1 className={'text-[2rem] font-bold'}>
                        {formatCurrency(price)}
                      </h1>

                      <span
                        className={`${
                          form.price ===
                          getPricePercentageOff({ product, percentage })
                            ? 'text-white'
                            : 'text-brand-secondary'
                        } font-semibold`}
                      >
                        {percentage}% off
                      </span>
                    </button>
                  );
                })}
              </div>

              {useFormReturn.formState.errors.price && (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  {useFormReturn.formState.errors.price.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex w-full flex-col items-center justify-center gap-[1.6rem] gap-y-8 place-self-end">
            <div className="h-px w-full bg-zinc-200" />

            <div className="w-full pt-2">
              <div className="flex flex-col gap-2">
                <Checkbox
                  label="I acknowledge that this offer is binding."
                  onChange={() => {
                    useFormReturn.setValue(
                      'termsAndConditions',
                      !form.termsAndConditions
                    );
                    useFormReturn.clearErrors('termsAndConditions');
                  }}
                  selected={form.termsAndConditions}
                  error={!!useFormReturn.formState.errors.termsAndConditions}
                />
                {useFormReturn.formState.errors.termsAndConditions && (
                  <span className="text-[1.5rem] font-semibold text-brand-red">
                    {useFormReturn.formState.errors.termsAndConditions.message}
                  </span>
                )}
              </div>
            </div>

            <Button
              type="secondary"
              text={'Continue'}
              buttonType="button"
              width="fluid"
              onClick={() => {
                if (checkErrors()) return;
                setCurrentStep(currentStep + 1);
              }}
            />
          </div>
        </div>
      ),
    },
    {
      id: 'shipping-payment' as StepType,
      content: (
        <div className="flex h-full flex-col justify-between gap-8">
          <div>
            <span className="text-[2.1rem] font-semibold text-black">
              Shipping & Payment
            </span>

            <div className="mt-8 flex flex-col gap-y-6">
              <div className="flex w-full justify-between">
                <span className="text-[1.8rem] font-semibold text-black">
                  Shipping To
                </span>

                <button
                  onClick={() => setOpenModal('address')}
                  className="pr-4 text-[1.5rem] font-medium text-brand-secondary"
                  type="button"
                >
                  Add
                </button>
              </div>

              {useFormReturn.formState.errors.shippingAddress && (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  {useFormReturn.formState.errors.shippingAddress.message}
                </span>
              )}

              <div className="px-2">
                <FormSelect
                  disabled={!addressOptions.length}
                  allowEmpty={false}
                  placeholder="Select shipping address"
                  error={!!useFormReturn.formState.errors.shippingAddress}
                  options={addressOptions}
                  rules={{ required: true }}
                  control={useFormReturn.control}
                  defaultValue={form.shippingAddress}
                  onChange={(e) => {
                    useFormReturn.setValue('rate', undefined);
                    if (product.is_flat_rate) {
                      const shipping = calculateShipping(
                        product,
                        getShippingRegion(JSON.parse(e.value))
                      );
                      if (!shipping) {
                        useFormReturn.setError('shippingAddress', {
                          type: 'manual',
                          message:
                            'No shipping options available for this product to this address',
                        });
                        return;
                      }
                      useFormReturn.setValue('shippingCost', shipping.cost);
                    }
                    useFormReturn.clearErrors('shippingAddress');
                  }}
                  name="shippingAddress"
                />
              </div>

              <div className="mt-8 flex w-full justify-between">
                <span className="text-[1.8rem] font-semibold text-black">
                  Payment Method
                </span>

                <button
                  onClick={() => setOpenModal('payment')}
                  className="pr-4 text-[1.5rem] font-medium text-brand-secondary"
                  type="button"
                >
                  Add
                </button>
              </div>

              {useFormReturn.formState.errors.paymentMethodId && (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  {useFormReturn.formState.errors.paymentMethodId.message}
                </span>
              )}

              <div className="px-2">
                <FormSelect
                  disabled={!paymentOptions?.length}
                  allowEmpty={false}
                  placeholder="Select payment method"
                  error={!!useFormReturn.formState.errors.paymentMethodId}
                  options={paymentOptions ?? []}
                  control={useFormReturn.control}
                  defaultValue={form.paymentMethodId}
                  name="paymentMethodId"
                />
              </div>
            </div>
          </div>

          <div>
            <Button
              type="secondary"
              text="Continue"
              buttonType="button"
              onClick={() => {
                if (checkErrors()) return;
                taxMutation.mutate(form);
                setCurrentStep((prev) => prev + 1);
              }}
              className="w-full"
            />
          </div>
        </div>
      ),
    },
    {
      id: 'shipping-options' as StepType,
      content: (
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col gap-[2.4rem]">
            <span className="text-[2.1rem] font-semibold text-black">
              Select Shipping
            </span>

            <div className="max-h-[55rem] overflow-y-scroll">
              <div className="flex flex-col gap-[1.6rem]">
                {ratesError && (
                  <div className="flex w-full flex-col gap-4">
                    <span className="text-[1.4rem] font-semibold text-brand-red">
                      {ratesError?.message}
                    </span>
                  </div>
                )}
                {form.shippingAddress && (
                  <ItemShippingSelect
                    chooseCheapestByDefault
                    hideProduct
                    product={product}
                    shipment={product.shipment}
                    shipTo={JSON.parse(form.shippingAddress)}
                    offer_amount={form.price}
                    selectedRateId={form.rate?.rate_id ?? null}
                    onOptionSelected={({ rate }) => {
                      useFormReturn.setValue('rate', {
                        rate_id: rate.rate_id,
                        total_amount: rate.total_amount,
                        carrier_code: rate.carrier_code,
                        delivery_days: rate.delivery_days,
                        carrier_delivery_days: rate.carrier_delivery_days,
                        service_type: rate.service_type,
                      });
                      useFormReturn.setValue('shippingCost', rate.total_amount);
                    }}
                    onError={(error) => {
                      setRatesError(error);
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <Button
            type="secondary"
            buttonType="button"
            onClick={() => {
              if (checkErrors()) return;
              taxMutation.mutate(form);
              setCurrentStep(
                steps.findIndex((step) => step.id === 'review-offer')
              );
            }}
            className="mt-4 w-full"
          >
            <>
              {!form.rate ? (
                <span className="text-[1.8rem] font-semibold text-white">
                  Please select a shipping option
                </span>
              ) : (
                <div className="flex items-center gap-x-4">
                  <span className="text-[1.8rem] font-semibold text-white">
                    Continue with
                  </span>

                  {form.rate?.carrier_code === 'fedex' ? (
                    <FedExIcon className="h-10 w-10" />
                  ) : form.rate?.carrier_code === 'ups' ? (
                    <UPS className="h-10 w-10" />
                  ) : (
                    <USPSIcon className="h-10 w-10" />
                  )}

                  <span className="text-[1.8rem] font-semibold text-white">
                    {form.rate?.delivery_days ??
                      form.rate?.carrier_delivery_days}{' '}
                    {(form.rate?.delivery_days ??
                      form.rate?.carrier_delivery_days) === 1
                      ? 'Day'
                      : 'Days'}{' '}
                    ({formatCurrency(form.rate?.total_amount ?? 0)})
                  </span>
                </div>
              )}
            </>
          </Button>
        </div>
      ),
    },
    {
      id: 'review-offer' as StepType,
      content: (
        <div className="flex h-full flex-col">
          <div className="flex flex-1 flex-col gap-[2.4rem]">
            <span className="text-[2.1rem] font-semibold text-black">
              Review Offer
            </span>

            <div className="flex items-center justify-between">
              <div className="flex max-w-[30rem] items-center gap-[1.6rem]">
                <SafeImage
                  className="h-[6rem] w-[6rem] shrink-0 rounded-2xl"
                  alt={'product image'}
                  src={product.thumbnail}
                />

                <div className="flex flex-col gap-[0.2rem]">
                  <div className="flex flex-col gap-y-2">
                    <span className="w-[22rem] truncate text-[1.7rem] font-semibold text-black">
                      {product.title}
                    </span>

                    <span className="flex flex-col text-[1.6rem] font-medium text-[#7F7F7F]">
                      {product.categories.join(' > ')}
                      {!!selectedVariations?.length && (
                        <>
                          <span>
                            Size: {letterSize?.size ?? ''}
                            {letterSize?.size && numberSize?.size ? '/' : ''}
                            {numberSize?.size ?? ''}
                          </span>
                          {colorVariation?.color && (
                            <span>Color: {colorVariation?.color}</span>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-x-4">
                <button
                  type="button"
                  className="text-[1.8rem] font-medium text-brand-secondary"
                  onClick={() => {
                    setCurrentStep(
                      steps.findIndex((step) => step.id === 'offer-price')
                    );
                  }}
                >
                  <PencilIcon className="h-8 w-8" />
                </button>
                <span className="text-[2.1rem] font-bold text-black">
                  {formatCurrency(form.price)}
                </span>
              </div>
            </div>

            <div className="h-px w-full bg-zinc-200" />

            <div className="flex flex-col gap-y-6">
              <div className="flex w-full justify-between">
                <span className="text-[1.8rem] font-semibold text-black">
                  Shipping To
                </span>

                <button
                  type="button"
                  onClick={() =>
                    setCurrentStep(
                      steps.findIndex((step) => step.id === 'shipping-payment')
                    )
                  }
                  className="pr-4 text-[1.5rem] font-medium text-brand-secondary"
                >
                  Edit
                </button>
              </div>

              {form.shippingAddress ? (
                <div className="flex items-center gap-x-4">
                  <div className="flex items-center gap-x-2">
                    <span className="text-[1.8rem] font-medium text-black">
                      {JSON.parse(form.shippingAddress).address_line1},{' '}
                      {JSON.parse(form.shippingAddress).city_locality},{' '}
                      {JSON.parse(form.shippingAddress).state_province}{' '}
                      {JSON.parse(form.shippingAddress).postal_code}
                    </span>
                  </div>
                </div>
              ) : (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  Please select a shipping address
                </span>
              )}

              <div className="mt-8 flex w-full justify-between">
                <span className="text-[1.8rem] font-semibold text-black">
                  Payment Method
                </span>

                <button
                  type="button"
                  onClick={() =>
                    setCurrentStep(
                      steps.findIndex((step) => step.id === 'shipping-payment')
                    )
                  }
                  className="pr-4 text-[1.5rem] font-medium text-brand-secondary"
                >
                  Edit
                </button>
              </div>

              {form.paymentMethodId &&
              paymentMethodData?.find(
                (pm) => pm.id === form.paymentMethodId
              ) ? (
                <div className="flex items-center gap-x-4">
                  <SafeImage
                    src={`/cc_brands/${paymentMethodData
                      .find((pm) => pm.id === form.paymentMethodId)
                      ?.brand?.toLowerCase()}.png`}
                    alt={
                      paymentMethodData.find(
                        (pm) => pm.id === form.paymentMethodId
                      )?.brand ?? ''
                    }
                    width={32}
                    height={20}
                    className="mr-2 h-20 w-20 object-contain"
                  />

                  <span className="text-[1.8rem] font-medium text-black">
                    {`Card ending in ${
                      paymentMethodData.find(
                        (pm) => pm.id === form.paymentMethodId
                      )?.last4
                    }`}
                  </span>
                </div>
              ) : (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  Please select a payment method
                </span>
              )}

              <div className="mt-4 h-px w-full bg-zinc-200" />

              <div className="flex flex-col gap-y-6">
                <div className="flex items-center justify-between">
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    Offer Price
                  </span>
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    {formatCurrency(form.price)}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <span className="flex gap-x-4 text-[1.8rem] font-medium text-[#444444]">
                    Shipping{' '}
                    {form.rate && (
                      <div className="flex items-center gap-x-4">
                        {form.rate.carrier_code === 'fedex' ? (
                          <FedExIcon className="h-8 w-8" />
                        ) : form.rate.carrier_code === 'ups' ? (
                          <UPS className="h-8 w-8" />
                        ) : (
                          <USPSIcon className="h-8 w-8" />
                        )}

                        <div className="flex grow items-center gap-5">
                          <h5 className="text-[1.6rem] font-semibold text-brand-secondary">
                            {form.rate.delivery_days ??
                              form.rate.carrier_delivery_days}{' '}
                            {(form.rate.delivery_days ??
                              form.rate.carrier_delivery_days) === 1
                              ? 'Day'
                              : 'Days'}
                          </h5>
                        </div>
                        <button
                          onClick={() => {
                            setCurrentStep(
                              steps.findIndex(
                                (step) => step.id === 'shipping-options'
                              )
                            );
                          }}
                          type="button"
                          className="text-[1.8rem] font-medium text-brand-secondary"
                        >
                          <PencilIcon className="h-8 w-8" />
                        </button>
                      </div>
                    )}
                  </span>

                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    {formatCurrency(form.shippingCost)}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    Tax
                  </span>

                  {taxMutation.isPending ? (
                    <span className="h-10 w-20 animate-pulse rounded-full bg-gray-200 text-[1.8rem] font-medium"></span>
                  ) : taxMutation.error ? (
                    <span className="text-[1.8rem] font-medium text-brand-red">
                      {taxMutation.error.message}
                    </span>
                  ) : (
                    <span className="text-[1.8rem] font-medium text-[#444444]">
                      {formatCurrency(form.tax)}
                    </span>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-[1.8rem] font-bold text-black">
                    You will pay
                  </span>
                  {taxMutation.isPending ? (
                    <span className="h-10 w-20 animate-pulse rounded-full bg-gray-200 text-[1.8rem] font-medium"></span>
                  ) : taxMutation.error ? (
                    <span className="text-[1.8rem] font-medium text-brand-red">
                      {taxMutation.error.message}
                    </span>
                  ) : (
                    <span className="text-[2rem] font-bold text-black">
                      {formatCurrency(
                        form.price + form.shippingCost + form.tax
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {useFormReturn.formState.errors.root && (
            <span className="text-center text-[1.5rem] font-semibold text-brand-red">
              {useFormReturn.formState.errors.root.message}
            </span>
          )}
          <div className="mt-8 pb-8">
            <Button
              type="secondary"
              text={'Submit Offer'}
              onClick={() => {
                useFormReturn.handleSubmit(onSubmit)();
              }}
              width="normal"
              loading={useFormReturn.formState.isSubmitting}
              className="w-full !flex-nowrap text-[1.8rem]"
            />
          </div>
        </div>
      ),
    },
  ].filter((step) =>
    product.is_flat_rate
      ? flatRateList.includes(step.id)
      : dynamicList.includes(step.id)
  );
  const checkErrors = () => {
    let hasErrors = false;
    switch (steps[currentStep].id) {
      case 'offer-price':
        // validate price
        validatePrice({ form, product }).forEach((error) => {
          hasErrors = true;
          useFormReturn.setError(
            'price',
            {
              type: 'manual',
              message: error,
            },
            {
              shouldFocus: true,
            }
          );
        });
        // validate terms and conditions
        validateTermsAndConditions(form).forEach((error) => {
          hasErrors = true;
          useFormReturn.setError(
            'termsAndConditions',
            {
              type: 'manual',
              message: error,
            },
            {
              shouldFocus: true,
            }
          );
        });
        break;
      case 'shipping-payment':
        validateShippingAddress(form).forEach((error) => {
          hasErrors = true;
          useFormReturn.setError(
            'shippingAddress',
            {
              type: 'manual',
              message: error,
            },
            {
              shouldFocus: true,
            }
          );
        });
        validatePaymentMethod(form).forEach((error) => {
          hasErrors = true;
          useFormReturn.setError(
            'paymentMethodId',
            {
              type: 'manual',
              message: error,
            },
            {
              shouldFocus: true,
            }
          );
        });
        break;
      case 'shipping-options':
        validateShippingOptions({ form, product }).forEach((error) => {
          hasErrors = true;
          useFormReturn.setError(
            'rate',
            {
              type: 'manual',
              message: error,
            },
            { shouldFocus: true }
          );
        });
    }
    return hasErrors;
  };
  return (
    <>
      <div className="flex h-[78rem] w-full flex-col sm:w-[55rem]">
        <NavigationBar stepCount={steps.length} currentStep={currentStep} />
        <form
          onSubmit={useFormReturn.handleSubmit(onSubmit)}
          className="flex h-full w-full justify-center p-4 sm:p-8"
        >
          <BProcess steps={steps} currentStep={currentStep} />
        </form>
      </div>

      {openModal === 'address' && (
        <AddAddressModal
          isOpen={true}
          dismiss={(address) => {
            setOpenModal('');

            if (address) {
              useFormReturn.setValue(
                'shippingAddress',
                JSON.stringify(address)
              );
            }
          }}
        />
      )}

      {openModal === 'payment' && (
        <AddPaymentMethodModal
          isOpen={true}
          dismiss={(paymentMethod) => {
            setOpenModal('');
            if (paymentMethod) {
              useFormReturn.setValue('paymentMethodId', paymentMethod);
            }
          }}
        />
      )}
    </>
  );
};

export const NavigationBar = ({
  stepCount,
  currentStep,
}: {
  stepCount: number;
  currentStep: number;
}) => (
  <div className="my-4 flex justify-between gap-x-4 p-4">
    {Array.from({ length: stepCount }).map((_, index) => {
      const isActive = index === currentStep;

      return (
        <div
          key={index}
          className={`h-1 w-full rounded-full ${
            isActive ? 'bg-brand-secondary' : 'bg-zinc-200'
          }`}
        />
      );
    })}
  </div>
);
